import React from 'react';
import { TranslateFunction, withTranslations } from 'react-utilities';
import { useLocation, useHistory, RouteComponentProps } from 'react-router-dom';
import gameCarouselTranslationConfig from '../../../../js/react/gameCarousel/translation.config';
import eventStreamConstants, {
  EventStreamMetadata,
  SessionInfoType
} from '../../common/constants/eventStreamConstants';
import usePageReferralTracker from '../../common/hooks/usePageReferralTracker';
import GamesSeeAllGrid from './GamesSeeAllGrid';
import { withPageSession } from '../../common/utils/PageSessionContext';

type TSortDetailExploreApiProps = {
  translate: TranslateFunction;
} & RouteComponentProps<{ sortName: string }>;

export const SortDetailExploreApi = ({
  match,
  translate
}: TSortDetailExploreApiProps): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const sortName = decodeURIComponent(match.params.sortName);

  usePageReferralTracker(
    eventStreamConstants.sortDetailReferral,
    [
      EventStreamMetadata.Position,
      EventStreamMetadata.GameSetTargetId,
      EventStreamMetadata.GameSetTypeId,
      EventStreamMetadata.Page,
      EventStreamMetadata.TreatmentType,
      SessionInfoType.DiscoverPageSessionInfo
    ],
    [
      // Do not cleanse the 'device' filter query param from the URL
      'device'
    ],
    {},
    location,
    history
  );

  return <GamesSeeAllGrid sortName={sortName} translate={translate} />;
};

export default withPageSession(
  withTranslations(SortDetailExploreApi, gameCarouselTranslationConfig)
);
